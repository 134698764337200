
.sh-research.i img,
.sh-research.i video{
	height: 100%;
}

.sh-research{
	z-index: 15;
}

.sh-research.rii-1.i{
	z-index: 17;
}

.sh-research.rii-1.border{
	z-index: 17;
}


.sh-research.rti-1.i{
	z-index: 16;
}

.sh-research.rti-1.border{
	z-index: 16;
}

.sh-research.rti-2.i{
	z-index: 18;
}

.sh-research.rti-2.border{
	z-index: 18;
}

.sh-research.rti-3.i{
	z-index: 18;
}

.sh-research.rti-3.border{
	z-index: 18;
}

.sh-research.rti-4.i{
	z-index: 16;
}

.sh-research.rti-4.border{
	z-index: 16;
}

.faf-research.i img,
.faf-research.i video{
	height: 100%;
}

.faf-research{
	z-index: 15;
}

.faf-research.rii-2.i,
.faf-research.rii-2.border{
	z-index: 17;
}

.faf-research.rii-3.i,
.faf-research.rii-3.border,
.faf-research.rii-4.i,
.faf-research.rii-4.border,
.faf-research.rii-6.i,.faf-research.rii-1.i,
.faf-research.rii-1.border
.faf-research.rii-6.border{
	z-index: 18;
}

.faf-research.rti-1.border,
.faf-research.rti-1.i{	
	z-index: 16;
}

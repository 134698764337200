
.kovelchanka-research.i img,
.kovelchanka-research.i video{
	height: 100%;
}

.kovelchanka-research{
	z-index: 15;
}

.kovelchanka-research.rii-1.i{
	z-index: 12;
}

.kovelchanka-research.rii-1.border{
	z-index: 12;
}

.kovelchanka-research.rii-4.i{
	z-index: 13;
}

.kovelchanka-research.rii-4.border{
	z-index: 13;
}

.kovelchanka-research.rii-3.i{
	z-index: 15;
}

.kovelchanka-research.rii-3.border{
	z-index: 15;
}

.kovelchanka-research.rii-2.i{
	z-index: 12;
}

.kovelchanka-research.rii-2.border{
	z-index: 12;
}


.kovelchanka-research.rti-1.i{
	z-index: 16;
}

.kovelchanka-research.rti-1.border{
	z-index: 16;
}

.kovelchanka-research.rti-2.i{
	z-index: 13;
}

.kovelchanka-research.rti-2.border{
	z-index: 13;
}

.kovelchanka-research.rti-3.i{
	z-index: 11;
}

.kovelchanka-research.rti-3.border{
	z-index: 11;
}

.kovelchanka-research.rti-4.i{
	z-index: 11;
}

.kovelchanka-research.rti-4.border{
	z-index: 11;
}
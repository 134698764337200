
.kh-research.i img,
.kh-research.i video{
	height: 100%;
}

.kh-research{
	z-index: 15;
}

.kh-research.rii-1.i{
	z-index: 18;
}

.kh-research.rii-1.border{
	z-index: 18;
}

.kh-research.rii-2.i{
	z-index: 18;
}

.kh-research.rii-2.border{
	z-index: 18;
}

.kh-research.rii-3.i{
	z-index: 18;
}

.kh-research.rii-3.border{
	z-index: 18;
}

.kh-research.rii-4.i{
	z-index: 16;
}

.kh-research.rii-4.border{
	z-index: 16;
}

.kh-research.rii-5.i{
	z-index: 18;
}

.kh-research.rii-5.border{
	z-index: 18;
}

.kh-research.rii-6.i{
	z-index: 18;
}

.kh-research.rii-6.border{
	z-index: 18;
}

.kh-research.rii-7.i{
	z-index: 18;
}

.kh-research.rii-7.border{
	z-index: 18;
}

.kh-research.rii-8.i{
	z-index: 15;
}

.kh-research.rii-8.border{
	z-index: 15;
}

.kh-research.rii-9.i{
	z-index: 15;
}

.kh-research.rii-9.border{
	z-index: 15;
}

.kh-research.rii-10.i{
	z-index: 15;
}

.kh-research.rii-10.border{
	z-index: 15;
}

.kh-research.rii-11.i{
	z-index: 15;
}

.kh-research.rii-11.border{
	z-index: 15;
}

.kh-research.rii-12.i{
	z-index: 15;
}

.kh-research.rii-12.border{
	z-index: 15;
}


.kh-research.rii-13.i{
	z-index: 15;
}

.kh-research.rii-13.border{
	z-index: 15;
}

.kh-research.rii-14.i{
	z-index: 15;
}

.kh-research.rii-14.border{
	z-index: 15;
}

.kh-research.rii-15.i{
	z-index: 15;
}

.kh-research.rii-15.border{
	z-index: 15;
}

.kh-research.rii-16.i{
	z-index: 15;
}

.kh-research.rii-16.border{
	z-index: 15;
}

.kh-research.rti-1.i{
	z-index: 16;
}

.kh-research.rti-1.border{
	z-index: 16;
}

.kh-research.rti-2.i{
	z-index: 16;
}

.kh-research.rti-2.border{
	z-index: 16;
}

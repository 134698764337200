
.fi-research.i img,
.fi-research.i video{
	height: 100%;
}

.fi-research{
	z-index: 15;
}

.fi-research.rii-1.i{
	z-index: 15;
}

.fi-research.rii-1.border{
	z-index: 15;
}


.fi-research.rti-1.i{
	z-index: 16;
}

.fi-research.rti-1.border{
	z-index: 16;
}


.fi-research.rvi-1.i{
	z-index: 18;
}

.fi-research.rvi-1.border{
	z-index: 18;
}

.m-research.i img,
.m-research.i video{
	height: 100%;
}

.m-research{
	z-index: 15;
}

.m-research.rii-1.i{
	z-index: 15;
}

.m-research.rii-1.border{
	z-index: 15;
}

.m-research.rii-2.i{
	z-index: 16;
}

.m-research.rii-2.border{
	z-index: 16;
}

.m-research.rii-3.i{
	z-index: 15;
}

.m-research.rii-3.border{
	z-index: 15;
}

.m-research.rti-1.i{
	z-index: 16;
}

.m-research.rti-1.border{
	z-index: 16;
}


.mg-research.i img,
.mg-research.i video{
	height: 100%;
}

.mg-research{
	z-index: 5;
}

.mg-research{
	bottom: unset;
	right: unset;
	left: unset;
	top: unset;
	width: unset;
	height: unset;
}

.mg-research.rii-1.i{
	z-index: 15;
}

.mg-research.rii-1.border{
	z-index: 15;
}

.mg-research.rii-2.i{
	z-index: 16;
}

.mg-research.rii-2.border{
	z-index: 16;
}

.mg-research.rii-3.i{
	z-index: 17;
}

.mg-research.rii-3.border{
	z-index: 17;
}

.mg-research.rii-4.i{
	z-index: 15;
}

.mg-research.rii-4.border{
	z-index: 15;
}

.mg-research.rii-5.i{
	z-index: 15;
}

.mg-research.rvi-5.border{
	z-index: 15;
}


.mg-research.rvi-1.i{
	z-index: 13;
}

.mg-research.rvi-1.border{
	z-index: 13;
}

.mg-research.rvi-1.i{
	z-index: 13;
}

.mg-research.rvi-1.border{
	z-index: 13;
}

.mg-research.rti-1.i{
	z-index: 16;
}

.mg-research.rti-1.border{
	z-index: 16;
}


.flc-research.i img,
.flc-research.i video{
	height: 100%;
}

.flc-research{
	z-index: 15;
}

.flc-research.rii-1.i{
	z-index: 15;
}

.flc-research.rii-1.border{
	z-index: 15;
}

.flc-research.rii-2.i{
	z-index: 17;
}

.flc-research.rii-2.border{
	z-index: 17;
}

.flc-research.rii-3.i{
	z-index: 15;
}

.flc-research.rii-3.border{
	z-index: 15;
}


.flc-research.rti-1.border{
	z-index: 16;
}

.flc-research.rti-1.i{	
	z-index: 16;
}

.flc-research.rti-2.border{
	z-index: 16;
}

.flc-research.rti-2.i{	
	z-index: 16;
}
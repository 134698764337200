
.fsg-research.i img,
.fsg-research.i video{
	height: 100%;
}

.fsg-research{
	z-index: 15;
}

.fsg-research.rii-1.i{
	z-index: 18;
}

.fsg-research.rii-1.border{
	z-index: 18;
}

.fsg-research.rii-2.i{
	z-index: 15;
}

.fsg-research.rii-2.border{
	z-index: 15;
}

.fsg-research.rii-3.i{
	z-index: 15;
}

.fsg-research.rii-3.border{
	z-index: 15;
}

.fsg-research.rii-6.i,
.fsg-research.rii-4.i{
	z-index: 18;
}

.fsg-research.rii-6.border,
.fsg-research.rii-4.border{
	z-index: 18;
}


.fsg-research.rti-1.i{
	z-index: 16;
}

.fsg-research.rti-1.border{
	z-index: 16;
}

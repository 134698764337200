
@import './about/index.css';
@import './composed-text/index.css';
@import './controls/index.css';
@import './intro/index.css';
@import './item-with-border/index.css';
@import './line-item/index.css';
@import './location-title/index.css';
@import './location/index.css';
@import './mobile/about/index.css';
@import './mobile/research/index.css';
@import './navigation/index.css';
@import './research/index.css';
@import './video/index.css';
@import './spinner/index.css';
@import './page-loader/index.css';

*{
  scrollbar-width: none;
}

body{
  width: 100%;
  overflow: hidden;
}

html, body, #root, #root > div, #root > div, .location-wrapper, #locations, .about  {
  height: 100%;
  font-family: Times New Roman;
}

.freezed * {
  pointer-events: none;
}

/*#root{
  background: white;
}*/

*{
  scroll-behavior: smooth !important;
}

.block{
  pointer-events: none !important;
  cursor: none !important;
}


::-webkit-scrollbar {
  display: none;
}

.control.close{
  z-index: 999;
  pointer-events: all;
}

.mob-about-wrapper{
  mix-blend-mode: difference;
  z-index: 120;
  margin: 0 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.mob-about-wrapper div{
  background: white;
  color: #7d7979;
  margin: 30px 0;
  padding: 30px;
  text-transform: uppercase;
  box-sizing: border-box;
}
.description{
  z-index: 106;
  width: 40%;
  -webkit-transform: translate(137%, 130px);
          transform: translate(137%, 130px);
  text-transform: uppercase;
  height: 75%;
  overflow: scroll;
  white-space: pre-line;
}

.description-box{
  white-space: pre-line;
  height: 80%;
  z-index: 105;
  width: 45%;
  background: #bfbfbf;
  mix-blend-mode: exclusion;
  color: transparent;
  -webkit-transform: translate(115%, 100px);
          transform: translate(115%, 100px);
}

.description,
.description-box{
  position: absolute;
  top: 0;
  left: 0;
  font-size: 23px;
  line-height: 26px;
}

.description div p{
  color: black;
  color: #838383;

  mix-blend-mode: difference;
  isolation: isolate;
}



#locations{
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}


.locations-wrapper{
  /*position: relative;*/
  /*overflow-y: hidden !important; */
}

.content-wrapper{
  width:  45%; 
  height: 80%;
  overflow:  scroll; 
  position: absolute; 
  top: 100px; 
  left: 100px;
}

.show-transition{
  z-index: 15;
  -webkit-animation: show-half 2s ease-out;
          animation: show-half 2s ease-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}



.composed-text,
.video-wrapper,
.description,
.description-box,
.about-wrapper,
.research-wrapper,
.i,
.border{
  -webkit-animation: show 1.5s ease-out;
          animation: show 1.5s ease-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}



.optimized {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0,0,0);
  -webkit-transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0,0,0);
}

div.border{
  background-color: white;
  mix-blend-mode: difference;
  z-index: 11;
  position: absolute;
}

.block-actions{
  pointer-events: none !important;
}

.move{
  transform: translateY(-100%);
  transition: transform 2s ease-in-out;
}

.move-down{
  transform: translateY(100%);
  transition: transform 2s ease-in-out;
}

.resizer{
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
  pointer-events: all;
}

.title{
  width: 100%; 
  height: 100%; 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex; 
  -webkit-box-orient: vertical; 
  -webkit-box-direction: normal; 
      -ms-flex-direction: column; 
          flex-direction: column; 
  color: white; 
}

.title > div{
  width: 200vw; 
  height: 30%; 
}

.about-video{
  width: 40%;
  position: absolute;
  top: 0px;
  left: 30px;
  z-index: 120;
  padding-top: 100px;
  height: 80%;
  overflow: scroll;
}


video:focus{
  outline: none;
}

.video-wrapper{
  position: relative;
  margin-bottom: 40px;
}

.video-wrapper::after {
  content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: calc(100% - 4px);
    border-width: 20px;
    border-color: white;
    border-style: solid;
    mix-blend-mode: difference;
    pointer-events: none;
}

video[poster]{
  -o-object-fit: cover;
     object-fit: cover;
  cursor: pointer;
}

.ctw{
  z-index: 120;
    position: absolute;
    top: calc(50% - 10%);
    left: calc(50% - 10%);
    height: 20%;
    width: 20%;
    pointer-events: none;
    cursor: pointer;
    mix-blend-mode: difference;
}

.videos div::after {
  content: "";
    position: absolute;
    left: 30px;
    top: 30px;
    width: calc(100% - 60px );
    height: calc(100% - 60px);
    border-width: 20px;
    border-color: white;
    border-style: solid;
    mix-blend-mode: difference;
    pointer-events: none;
  }


.mobile-research-wrapper{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.mobile-research-item{
  margin: 30px auto;
  padding: 20px;
}

.mobile-research-item .border{
  height: 100%;
}

.mobile-research-wrapper .i{
  position: static;
}

.bygone-wrapper * {
  z-index: 13;
}
.bygone-wrapper{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%; 
  z-index: 13;
  /*transform: translateY(100%);*/
}

.logo-title{
  width: 80%;
  z-index: 1;
}

.logo-title svg{
  width: 100%;
}

.logo-wrapper{
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%; 
  /*z-index: 13;*/
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}


.bl{
  filter: invert(0);
  transition: filter 4s linear;
}
.invert {
filter: invert(1);
    }

.bygone{
  background: -webkit-linear-gradient(333deg, rgba(255, 255, 255, 0) 27.59%, rgba(176, 0, 148, 0.69) 45.9%, rgba(176, 0, 148, 0) 66.66%), -webkit-linear-gradient(218.71deg, #5CCB18 15.57%, rgba(0, 19, 194, 0.575794) 39.61%, rgba(222, 213, 2, 0.26792) 57.06%, rgba(255, 255, 255, 0) 72.24%), -webkit-linear-gradient(303.29deg, #FA0000 19.82%, rgba(255, 255, 255, 0) 45.99%), #C4C4C4;
  background: linear-gradient(117deg, rgba(255, 255, 255, 0) 27.59%, rgba(176, 0, 148, 0.69) 45.9%, rgba(176, 0, 148, 0) 66.66%), linear-gradient(231.29deg, #5CCB18 15.57%, rgba(0, 19, 194, 0.575794) 39.61%, rgba(222, 213, 2, 0.26792) 57.06%, rgba(255, 255, 255, 0) 72.24%), linear-gradient(146.71deg, #FA0000 19.82%, rgba(255, 255, 255, 0) 45.99%), #C4C4C4;
  mix-blend-mode: exclusion;
  width: 100%;
  height: 100%;
}

.bygone.logo{
  opacity: 0.5;
}

body{
  background: -webkit-linear-gradient(315.36deg, #8F8F8F 21.69%, rgba(255, 255, 255, 0) 49.89%), linear-gradient(180deg, #383838 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(117deg, rgba(255, 255, 255, 0) 27.59%, rgba(162, 86, 149, 0.69) 45.9%, rgba(176, 0, 148, 0) 66.66%), linear-gradient(231.29deg, #71BC43 15.57%, rgba(0, 19, 194, 0.575794) 39.61%, rgba(222, 213, 2, 0.26792) 57.06%, rgba(255, 255, 255, 0) 72.24%), linear-gradient(146.71deg, #D67D7D 19.82%, rgba(255, 255, 255, 0) 45.99%), #818181;
  background: linear-gradient(315.36deg, #8F8F8F 21.69%, rgba(255, 255, 255, 0) 49.89%), linear-gradient(180deg, #383838 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(117deg, rgba(255, 255, 255, 0) 27.59%, rgba(162, 86, 149, 0.69) 45.9%, rgba(176, 0, 148, 0) 66.66%), linear-gradient(231.29deg, #71BC43 15.57%, rgba(0, 19, 194, 0.575794) 39.61%, rgba(222, 213, 2, 0.26792) 57.06%, rgba(255, 255, 255, 0) 72.24%), linear-gradient(146.71deg, #D67D7D 19.82%, rgba(255, 255, 255, 0) 45.99%), #818181;
  /*mix-blend-mode: exclusion;*/
}

.content > div{
  margin: 40px;
  font-size: 40px;
}

.locations-list-wrapper{
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.videos > div{
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}


.border:hover .sizer-wrapper,
.border:hover + .i .sizer-wrapper,
.i:hover .sizer-wrapper{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.sizer-wrapper{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  mix-blend-mode: difference;
  display: none;
}

/*.border, .i{
  -webkit-transition: width 0.15s ease-out, height 0.15s ease-out;
  -o-transition: width 0.15s ease-out, height 0.15s ease-out;
  transition: width 0.15s ease-out, height 0.15s ease-out;
}*/

/*.line-item img{
  -webkit-transition: -webkit-clip-path 0.1s ease-out;
  transition: -webkit-clip-path 0.1s ease-out;
  -o-transition: clip-path 0.1s ease-out;
  transition: clip-path 0.1s ease-out;
  transition: clip-path 0.1s ease-out, -webkit-clip-path 0.1s ease-out;
}*/

.mobile-research-wrapper{
  padding: 40px;
}

.mobile-research-item{
  position: relative;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
}

.mobile-research-item .i *,
.mobile-research-item .i,
.mobile-research-item .border{
  width: 100%;
}

.video-item-wrapper{
  position: relative;
}

.exclusion-screen{
  position: absolute;
  background: white;
  z-index: 999;
  mix-blend-mode: exclusion;
  top: 0;
  height: 100%;
  width: 100%;
  animation: blink 2s ease-in-out;
  -webkit-animation: blink 1.5s ease-out;
  -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  pointer-events: none;
}

.upk-research.rti-3{
  background: #C4C4C4;
}

.stop{
  opacity: 0;
  pointer-events: none;
}

.show-stop{
  animation: show 30s linear;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes show-half {
  0% {
    opacity: 0;
  }
  100%{
    opacity: 0.5;
  }
}


@keyframes show-hlaf {
  0% {
    opacity: 0;
  }
  100%{
    opacity: 0.5;
  }
}

@-webkit-keyframes show {
  0% {
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}


@keyframes show {
  0% {
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  50%{
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50%{
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes remove {
  0% {
    display: block;
  }
  100%{
    display: none;
  }
}

@keyframes remove {
  0% {
    display: block;
  }
  100%{
    display: none;
  }
} 
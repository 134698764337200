
@media screen and (max-width: 540px){
	.mob-controls svg{
		width: 100px;
		height: 100px;
		z-index: 120;
		pointer-events: all;
	}


	nav{
		flex-direction: column;
    	align-items: flex-start !important;
	}

	.nav-item{
		font-size: 20vw !important;
	}

	/*.control.close:first-of-type{
		transform: translateY(200%);
	}*/
  }

  @media screen and (min-width: 540px) and (max-width: 1024px){
	nav{
		flex-direction: column;
    	align-items: flex-start !important;
	}

	.nav-item{
		font-size: 20vw;
	}

	/*.control.close:first-of-type{
		transform: translateY(40vh);
	}*/
  }

  .kh-title{
	font-size: calc(6.5vh + 6.5vw);
	}
	  
  .ch-title,
  .hoc-title,
  .u-title{
		font-size: calc(7vh + 7vw);
	}

	.barracks-title,
	.sh-title,
	.synagogue-title,
	.college-title,
	.kovelchanka-title {
		font-size: calc(8vh + 8vw);
	}


	.cg-title,
	.synagogue-title{
		font-size: calc(9vh + 9vw);
	}

	.upk-title .upk-location{
		font-size: calc(11vh + 11vw);	
	}

	.faf-title,
	.flc-title,
	.muranow-title,
	.nc-title,
  	.mg-title,
	.upk-title{
		font-size: calc(12vh + 12vw);	
	}

	.oc-title,
	.ts-title,
	.sf-title{
		font-size: calc(13vh + 13vw);
	}

	.sochy-title,
	.bakhiv-title{
		font-size: calc(16vh + 16vw);
	}

	.sumy-title{
		font-size: calc(18vh + 18vw);
	}

  
	@media (aspect-ratio: 4/3) {
		.upk-title{
			font-size: calc(12vh + 12vw);
		}
	}
	


  @media (max-width: 840px) and (orientation: landscape) { 
	.controls{
		align-items: flex-end;
	}

	.controls svg{
		width: 50%;
		height: 50%;
	}

	nav{
		flex-direction: row;
	}

	.nav-item{
		font-size: 7vw;
	}

	/*.control.close:first-of-type{
		transform: translateY(15vh);
	}*/
 }


/*iphone x*/
@media (max-height: 375px) and (min-width: 800px){
	.upk-3 + .clip-text{
		font-size: calc(4.5vw + 4.5vh);
	}
}


/*ipad pro*/
@media (max-height: 1024px){
	.upk-3 + .clip-text{
		font-size: calc(6vw + 6vh);
	}
}

.upk-8 + .clip-text{
   font-size: calc(4vw + 4vh);
}

.upk-3 + .clip-text,
.upk-9 + .clip-text{
   font-size: calc(5vw + 5vh);
}


.u-research.i img{
	height: 100%;
}

.u-research.rii-1.i{
	z-index: 12;
}

.u-research.rii-1.border{	
	z-index: 12;
}	
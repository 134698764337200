
.sf-research.i img,
.sf-research.i video{
	height: 100%;
}

.sf-research{
	z-index: 15;
}

.sf-research.rii-1.i{
	z-index: 15;
}

.sf-research.rii-1.border{
	z-index: 15;
}

.sf-research.rii-2.i{
	z-index: 18;
}

.sf-research.rii-2.border{
	z-index: 18;
}

.sf-research.rii-4.i{
	z-index: 16;
}

.sf-research.rii-4.border{
	z-index: 16;
}

.sf-research.rii-5.i{
	z-index: 16;
}

.sf-research.rii-5.border{
	z-index: 16;
}

.sf-research.rii-3.i{
	z-index: 19;
}

.sf-research.rii-3.border{
	z-index: 19;
}

.sf-research.rii-7.i{
	z-index: 16;
}

.sf-research.rii-7.border{
	z-index: 16;
}

.sf-research.rii-6.i{
	z-index: 15;
}

.sf-research.rii-6.border{
	z-index: 15;
}


.sf-research.rti-1.i{
	z-index: 16;
}

.sf-research.rti-1.border{
	z-index: 16;
}


.ts-research.i img,
.ts-research.i video{
	height: 100%;
}

.ts-research{
	z-index: 15;
}

.ts-research.rii-1.i{
	z-index: 15;
}

.ts-research.rii-1.border{
	z-index: 15;
}

.ts-research.rii-2.i{
	z-index: 18;
}

.ts-research.rii-2.border{
	z-index: 18;
}

.ts-research.rii-3.i{
	z-index: 16;
}

.ts-research.rii-3.border{
	z-index: 16;
}

.ts-research.rii-4.i{
	z-index: 16;
}

.ts-research.rii-4.border{
	z-index: 16;
}

.ts-research.rii-5.i{
	z-index: 10;
}

.ts-research.rii-5.border{
	z-index: 10;
}


.ts-research.rii-6.i{
	z-index: 10;
}

.ts-research.rii-6.border{
	z-index: 10;
}

.ts-research.rii-7.i{
	z-index: 15;
}

.ts-research.rii-7.border{
	z-index: 15;
}

.ts-research.rti-1.i{
	z-index: 16;
}

.ts-research.rti-1.border{
	z-index: 16;
}


.oc-research.i img,
.oc-research.i video{
	height: 100%;
}

.oc-research{
	z-index: 15;
}

.oc-research.rii-1.i{
	z-index: 18;
}

.oc-research.rii-1.border{
	z-index: 18;
}

.oc-research.rii-3.i{
	z-index: 15;
}

.oc-research.rii-3.border{
	z-index: 15;
}

.oc-research.rii-2.i{
	z-index: 15;
}

.oc-research.rii-2.border{
	z-index: 15;
}

.oc-research.rii-4.i{
	z-index: 15;
}

.oc-research.rii-4.border{
	z-index: 15;
}

.oc-research.rii-5.i{
	display: none;
}

.oc-research.rii-5.border{
	display: none;
}


.oc-research.rti-1.i{
	z-index: 16;
}

.oc-research.rti-1.border{
	z-index: 16;
}

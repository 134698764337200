
.s-research.i img,
.s-research.i video{
	height: 100%;
}

.s-research{
	z-index: 15;
}

.s-research.rii-1.i{
	z-index: 16;
}

.s-research.rii-1.border{
	z-index: 16;
}

.s-research.rti-2.i{
	z-index: 16;
}

.s-research.rti-2.border{
	z-index: 16;
}


.b-research.i img,
.b-research.i video{
	height: 100%;
}

.b-research{
	z-index: 15;
}

.b-research.rii-1.i{
	z-index: 15;
}

.b-research.rii-1.border{
	z-index: 15;
}

.b-research.rii-2.i{
	z-index: 15;
}

.b-research.rii-2.border{
	z-index: 15;
}

.b-research.rii-3.i{
	z-index: 16;
}

.b-research.rii-3.border{
	z-index: 16;
}

.b-research.rii-4.i{
	z-index: 16;
}

.b-research.rii-4.border{
	z-index: 16;
}

.b-research.rii-5.i{
	z-index: 16;
}

.b-research.rii-5.border{
	z-index: 16;
}


.b-research.rii-6.i{
	z-index: 16;
}

.b-research.rii-6.border{
	z-index: 16;
}


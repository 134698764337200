
.hoc-research.i img,
.hoc-research.i video{
	height: 100%;
}

.hoc-research{
	z-index: 15;
}

.hoc-research.rii-1.i{
	z-index: 15;
}

.hoc-research.rii-1.border{
	z-index: 15;
}

.hoc-research.rii-2.i{
	z-index: 15;
}

.hoc-research.rii-2.border{
	z-index: 15;
}

.hoc-research.rii-3.border{
	z-index: 15;
}

.hoc-research.rii-3.i{
	z-index: 15;
}

.hoc-research.rii-4.border{
	z-index: 15;
}

.hoc-research.rii-4.i{
	z-index: 15;
}

.hoc-research.rii-5.border{
	z-index: 15;
}

.hoc-research.rii-5.i{
	z-index: 15;
}


.hoc-research.rii-6.border{
	z-index: 15;
}

.hoc-research.rii-6.i{
	z-index: 15;
}

.hoc-research.rii-7.i{
	z-index: 15;
}

.hoc-research.rii-7.border{
	z-index: 15;
}

.hoc-research.rii-8.border{
	z-index: 15;
}

.hoc-research.rii-8.i{
	z-index: 15;
}

.hoc-research.rti-1.i{
	z-index: 16;
}

.hoc-research.rti-1.border{
	z-index: 16;
}

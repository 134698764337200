.upk-research.i video{
	height: 100%;
	/*width: 100%*/
}

.upk-research.i video{
	/*width: 100%*/
}

.upk-research{
	z-index: 15;
	width: auto;
	bottom: unset;
	right: unset;
	left: unset;
	top: unset;
}

.upk-research.rii-1.i{
	z-index: 16;
}

.upk-research.rii-1.border{
	z-index: 16;
}

.upk-research.rii-2.i{
	z-index: 16;
}

.upk-research.rii-2.border{
	z-index: 16;
}

.upk-research.rii-3.i{
	z-index: 16;
}

.upk-research.rii-3.border{
	z-index: 16;
}

.upk-research.rii-4.i{
	z-index: 18;
}

.upk-research.rii-4.border{
	z-index: 18;
}

.upk-research.rii-5.i{
	z-index: 19;
}

.upk-research.rii-5.border{
	z-index: 19;
}


.upk-research.rii-6.i{
	z-index: 16;
}

.upk-research.rii-6.border{
	z-index: 16;
}

.upk-research.rti-1.i{
	z-index: 16;
text-align: justify;
text-transform: uppercase;
}

.upk-research.rti-1.i,
.upk-research.rti-1.border{
	z-index: 16 !;
}

.upk-research.rti-2.i{
	z-index: 15;
	text-align: justify;
	text-transform: uppercase;
}

.upk-research.rti-2.border{
	z-index: 15;
}

.upk-research.rvi-1.i{
	z-index: 13;
}

.upk-research.rvi-1.border{
	z-index: 13;
}

.upk-research.rvi-2.i{
	z-index: 16;
}

.upk-research.rvi-2.border{
	z-index: 16;
}